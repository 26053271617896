.app {
	width: 100%;
	display: flex;
	flex-direction: column;
	align-items: center;
	position: relative;
}
.loader-fade-out {
	position: absolute;
	top: 0;
	z-index: 3;
	height: 103vh;
	width: 100vw;
	background-color: black;
	opacity: 0;
	animation: 0.5s load-fade-out 5.5s forwards ease-in;
}
@keyframes load-fade-out {
	100% {
		opacity: 1;
	}
}
.loader-fade-in {
	position: absolute;
	top: 0;
	z-index: 3;
	height: 103vh;
	width: 100vw;
	background-color: black;
	opacity: 1;
	animation: 2s load-fade-in 0.1s forwards ease-out;
}

@keyframes load-fade-in {
	100% {
		opacity: 0;
	}
}

.helper-text {
	z-index: 2;
	font-family: 'BenguiatBold', 'serif';
	font-size: 0.75rem;
	display: flex;
	flex-direction: column;
	justify-content: center;
	text-align: center;
}
.helper-text-upper {
	color: rgb(54, 48, 52);
	text-shadow: 0px 0px 2px rgb(239, 255, 245);
}
.helper-text-lower {
	color: rgb(239, 255, 245);
	text-shadow: 0px 0px 2px rgb(54, 48, 52);
}
