.sweater-background::before {
	content: ' ';
	position: absolute;
	left: 0;
	top: -3vw;
	width: 100%;
	/*  */
	/* Height is derived by the height of the .title element, 
	.sweater-container, and the top (-3vw) for this own element */
	/* height: 80.07vw; */
	height: 100%;
	/* min-height: calc(3vw + 30vw + 264px); */
	/*  */
	/*  */
	opacity: 0.3;
	background-image: url('../pattern.svg');
}
.sweater-container {
	display: flex;
	flex-direction: column;
	align-items: center;
	/* height: 47.06vw; */
	height: 60vh;
	min-height: 264px;
}

.sweater {
	display: flex;
	flex-direction: row;
	flex-wrap: nowrap;
	justify-content: space-between;
	/* margin-top: 5vh; */
}

ul {
	padding-inline-start: 0;
}

.row-1 {
	width: 75vw;
}

.row-2 {
	width: 85vw;
}
.row-3 {
	width: 80vw;
	/* margin-left: -2em; */
}

@supports (-webkit-overflow-scrolling: touch) {
	.sweater-container {
		display: flex;
		flex-direction: column;
		align-items: center;
		margin-left: -55px;
		/* padding-top: 50px; */
	}
}
