.letter {
	font-family: Redrum;
	position: absolute;
	font-size: 2em;
	top: 1em;
}

.sweater li {
	position: relative;
	-webkit-animation-fill-mode: both;
	animation-fill-mode: both;
	animation-iteration-count: infinite;
	-webkit-animation-iteration-count: infinite;
	list-style: none;
	margin: 0;
	padding: 0;
	display: block;
	width: 1em;
	min-width: 1rem;
	height: 2em;
	border-radius: 50%;
	margin: 0.75rem;
	display: inline-block;
	background: rgba(240, 36, 131, 0.6);
	box-shadow: 0px 0.3em 1.4em 0.2em rgba(240, 36, 131, 0.502);
}
.row-1 li:nth-child(4),
.row-1 li:nth-child(8),
.row-2 li:nth-child(1),
.row-3 li:nth-child(1),
.row-2 li:nth-child(4),
.row-2 li:nth-child(8) {
	background: rgba(66, 178, 98, 0.7);
	box-shadow: 0px 0.3em 1.4em 0.2em rgba(66, 178, 98, 0.502);
}
.row-1 li:nth-child(1),
.row-1 li:nth-child(6),
.row-2 li:nth-child(5),
.row-3 li:nth-child(2),
.row-3 li:nth-child(3),
.row-3 li:nth-child(7) {
	background: rgba(209, 183, 68, 0.7);
	box-shadow: 0px 0.3em 1.4em 0.2em rgba(209, 183, 68, 0.502);
}

.row-1 li:nth-child(2),
.row-1 li:nth-child(5),
.row-2 li:nth-child(3),
.row-3 li:nth-child(4),
.row-3 li:nth-child(6) {
	background: rgba(18, 157, 221, 0.7);
	box-shadow: 0px 0.3em 1.4em 0.2em rgba(18, 157, 221, 0.502);
}

.sweater li:before {
	content: '';
	position: absolute;
	background: #222;
	width: 0.7em;
	height: 0.55em;
	border-radius: 0.2em;
	top: -0.3em;
	left: 0.2em;
}
.sweater li:after {
	content: '';
	top: -0.9em;
	left: 0.45em;
	position: absolute;
	width: 10vw;
	/* min-width: 50px; */
	height: 1.1em;
	border-bottom: solid #222 0.1em;
	border-radius: 50%;
}
.sweater li:last-child:after {
	content: none;
}

.flash-yellow {
	background: #fff;
	box-shadow: 0px 0.3em 1.4em 1em rgba(209, 183, 68, 1);
	animation: 0.8s fade-yellow forwards ease-out;
}
@keyframes fade-yellow {
	100% {
		background: rgba(209, 183, 68, 0.05);
		box-shadow: 0px 0.3em 0.2em 1em rgba(209, 183, 68, 0.502);
	}
}

.flash-blue {
	background: #fff;
	box-shadow: 0px 0.3em 1.4em 1em rgba(18, 157, 221, 1);
	animation: 0.8s fade-blue forwards ease-out;
}
@keyframes fade-blue {
	100% {
		background: rgba(18, 157, 221, 0.05);
		box-shadow: 0px 0.3em 0.2em 1em rgba(18, 157, 221, 0.502);
	}
}

.flash-pink {
	background: #fff;
	box-shadow: 0px 0.3em 1.4em 1em rgba(240, 36, 131, 1);
	animation: 0.8s fade-pink forwards ease-out;
}
@keyframes fade-pink {
	100% {
		background: rgba(240, 36, 131, 0.03);
		box-shadow: 0px 0.3em 0.2em 1em rgba(240, 36, 131, 0.502);
	}
}

.flash-green {
	background: #fff;
	box-shadow: 0px 0.3em 1.4em 1em rgba(66, 178, 98, 1);
	animation: 0.8s fade-green forwards ease-out;
}
@keyframes fade-green {
	100% {
		background: rgba(66, 178, 98, 0.03);
		box-shadow: 0px 0.3em 0.2em 1em rgba(66, 178, 98, 0.502);
	}
}
