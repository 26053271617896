.title {
	font-family: 'BenguiatBold', 'serif';
	background: #fff;
	overflow: hidden;
	width: 100%;
	min-height: 30vw;
}

.st {
	z-index: 1;
	display: flex;
	flex-direction: column;
	align-items: center;
	text-transform: uppercase;
	font-size: 12.5vw;
	font-weight: 800;
	/* color: hsl(7, 87%, 6%); */
	color: hsl(127, 87%, 94%);
	text-shadow: -0.05rem -0.05rem 1px hsl(120, 41%, 12%),
		0.05rem -0.05rem 1px hsl(120, 41%, 12%),
		-0.05rem 0.05rem 1px hsl(120, 41%, 12%),
		0.05rem 0.05rem 1px hsl(120, 41%, 12%), 0 0 15px hsl(120, 100%, 98%),
		0 0 20px hsl(120, 100%, 99%);
	/* text-shadow: -0.05rem -0.05rem 1px #ed2b12, 0.05rem -0.05rem 1px #ed2b12,
		-0.05rem 0.05rem 1px #ed2b12, 0.05rem 0.05rem 1px #ed2b12, 0 0 15px #630100,
		0 0 20px #450100; */
	letter-spacing: -0.6vw;
	position: relative;
	opacity: 0;
	transform: translateY(-20%);
	animation: 3s fade-in 0.5s forwards;
	-webkit-animation: 3s fade-in 0.5s forwards;
}
@keyframes fade-in {
	20% {
		opacity: 0.3;
	}
	100% {
		transform: translateY(10%);
		opacity: 1;
		width: 100%;
	}
}
@-webkit-keyframes fade-in {
	20% {
		opacity: 0.3;
	}
	100% {
		opacity: 1;
		width: 100%;
	}
}

.st p {
	display: flex;
	flex-direction: row;
	margin: 0;
	padding: 0;
	line-height: 20vw;
}
.st-stranger {
	position: relative;
	/* transform: translateY(-20%);
  animation: 1s glide-down 0.5s forwards; */
}
@keyframes glide-down {
	100% {
		transform: translateY(10%);
	}
}
.st-drop {
	line-height: 25.5vw;
}

@keyframes slide-right {
	100% {
		transform: translateX(0vw);
		opacity: 1;
	}
}

@-webkit-keyframes slide-right {
	100% {
		transform: translateX(0vw);
		opacity: 1;
	}
}

.st-stranger-s {
	font-size: 20vw;
	transform: translateX(-30vw);
	-webkit-transform: translateX(-30vw);
	animation: 3s slide-right 0.5s forwards;
	-webkit-animation: 3s slide-right 0.5s forwards;
}

.st-stranger-t {
	letter-spacing: 0;
	transform: translateY(-4vw);
	opacity: 0;
	animation: 2.5s slide-down 0.5s forwards;
	-webkit-animation: 2.5s slide-down 0.5s forwards;
}

.st-stranger-n {
	letter-spacing: -0.6vw;
}
.st-stranger-e {
	transform: translateY(-7vw);
	opacity: 0;
	animation: 2s slide-down 1s forwards;
}
@keyframes slide-down {
	100% {
		transform: translateY(0vw);
		opacity: 1;
	}
}
@-webkit-keyframes slide-down {
	100% {
		transform: translateY(0vw);
		opacity: 1;
	}
}

.st-stranger-g {
	letter-spacing: 0;
}

.st-stranger-r-2 {
	font-size: 20vw;
	opacity: 0;
	transform: translateX(100vw);
	-webkit-transform: translateX(100vw);
	animation: 2.5s slide-left 0.5s forwards;
	-webkit-animation: 2.5s slide-left 0.5s forwards;
}

@keyframes slide-left {
	100% {
		transform: translateX(0vw);
		opacity: 1;
	}
}
@-webkit-keyframes slide-left {
	100% {
		transform: translateX(0vw);
		opacity: 1;
	}
}

.st-bound {
	box-shadow: -0.05rem -0.05rem 2px hsl(120, 41%, 12%),
		0.05rem -0.05rem 2px hsl(120, 41%, 12%),
		-0.05rem 0.05rem 2px hsl(120, 41%, 12%),
		0.05rem 0.05rem 2px hsl(120, 41%, 12%);
	/* box-shadow: -0.05rem -0.05rem 2px #ed2b12, 0.05rem -0.05rem 2px #ed2b12,
		-0.05rem 0.05rem 2px #ed2b12, 0.05rem 0.05rem 2px #ed2b12; */
	height: 0.8vw;
}

.st-bound-full {
	width: 0vw;
	margin: 0;
	margin-bottom: -3.5vw;
	margin-top: 0.5vw;
	margin-left: 0.7vw;
	animation: 1.75s grow-top 2.25s ease-in-out forwards;
	-webkit-animation: 1.75s grow-top 2.25s ease-in-out forwards;
}
@-webkit-keyframes grow-top {
	0% {
		width: 0;
	}
	100% {
		width: 95vw;
	}
}
@keyframes grow-top {
	0% {
		width: 0;
	}
	100% {
		width: 95vw;
	}
}

.left-bound {
	width: 9vw;
	margin-left: 1vw;
	display: flex;
	flex-direction: row-reverse;
}
.st-bound-left {
	width: 0vw;
	margin-left: -6vw;
	-webkit-animation: 2s grow-left 2s linear forwards;
	animation: 2s grow-left 2s linear forwards;
}
@-webkit-keyframes grow-left {
	0% {
		width: 0;
	}

	100% {
		width: 14vw;
	}
}
@keyframes grow-left {
	0% {
		width: 0;
	}

	100% {
		width: 14vw;
	}
}

.right-bound {
	width: 16vw;
}
.st-bound-right {
	width: 0;
	animation: 2s grow-right 2.5s ease-in forwards;
	-webkit-animation: 2s grow-right 2.5s ease-in forwards;
}
@keyframes grow-right {
	0% {
		width: 0;
	}
	100% {
		width: 18vw;
	}
}
@-webkit-keyframes grow-right {
	0% {
		width: 0;
	}
	100% {
		width: 18vw;
	}
}

.st-bottom {
	display: flex;
	flex-direction: row;
	flex-wrap: nowrap;
	max-width: 85vw;
	min-width: 85vw;
	letter-spacing: -0.6vw;
	font-size: 10.5vw;
	margin-top: -3.75vw;
	margin-left: 0.7vw;
	transform: translateY(110%);
	animation: 3s glide-up 0.5s forwards;
	-webkit-animation: 3s glide-up 0.5s forwards;
}
@keyframes glide-up {
	100% {
		transform: translateY(0%);
		/* opacity: 100%; */
	}
}

@-webkit-keyframes glide-up {
	100% {
		transform: translateY(0%);
		/* opacity: 100%; */
	}
}

.st-things-s {
	font-size: 104%;
	margin-left: 0.4vw;
	letter-spacing: 0.2vw;
	opacity: 0;
	transform: translateX(-5vw);
	animation: 3s slide-right 0.5s forwards;
	-webkit-animation: 3s slide-right 0.5s forwards;
}

.st-things-w {
	letter-spacing: -0.3vw;
	transform: translateY(2vw);
	opacity: 0;
	animation: 2.5s slide-up 1s forwards;
	-webkit-animation: 2.5s slide-up 1s forwards;
}

.st-things-e {
	letter-spacing: -0.5vw;
}
.st-things-a {
	letter-spacing: -1.6vw;
}
.st-things-t {
	letter-spacing: -0.4vw;
}
.st-things-r {
	margin-left: 1vw;
	letter-spacing: -0.3vw;
	transform: translateY(4vw);
	opacity: 0;
	animation: 2s slide-up 0.5s forwards;
}
@keyframes slide-up {
	100% {
		transform: translateY(0vw);
		opacity: 1;
	}
}
@-webkit-keyframes slide-up {
	100% {
		transform: translateY(0vw);
		opacity: 1;
	}
}

.st-bottom p {
	margin-top: -9.5vw;
}

@supports (-webkit-overflow-scrolling: touch) {
	.st {
		margin-top: 10vw;
	}
	.st-stranger-r-2 {
		opacity: 1;
	}
	.st-things-s {
		opacity: 1;
	}
	.title {
		min-height: 33vw;
	}
}
